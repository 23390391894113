import React from 'react'
import { useMediaQuery } from 'react-responsive'

function MainPage() {
    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });
    return (
        <div >
            <div style={{
                display: "flex",
                justifyContent: "space-evenly",
                padding: "97px 0 97px 0"
            }}>
                <div style={{ color: "white", width: isXL ? "35%" : isLG ? "45%" : isMD ? "55%" : isSM ? "65%" : "75%" }}>
                    <p style={{ fontSize: "13px", margin: 0, letterSpacing: "1.63px" }}>FROM PROFESSIONALS</p>
                    <p style={{ fontSize: "48px", fontWeight: 900, marginTop: "17px", marginBottom: "23px" }}>Get the service you need in minutes!</p>
                    <p style={{ fontSize: "20px", margin: 0 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    <div style={{
                        display: "flex",
                        gap: "15px",
                        marginTop: "61px"
                    }}>
                        <img src="/assets/images/google-play-store-badge.png" alt="google-play-store-badge" style={{ height: "50px", objectFit: "contain" }} />
                        <img src="/assets/images/app-store-badge.png" alt="app-store-badge" style={{ height: "50px", objectFit: "contain" }} />
                    </div>
                </div>
                {(isXL || isLG || isMD) && <img src="/assets/images/inapp-screen-shoot.png" alt="service-provider-screen-in-app" height={"551px"} />}
            </div>
        </div>
    )
}

export default MainPage