import React from 'react'

function Header() {
    return (
        <div className='header' >
            <img src="/assets/images/header-logo.svg" alt="header logo" style={{ cursor: "pointer" }} onClick={() => window.location.href = "/"} />
        </div>
    )
}

export default Header