import { Route, Routes } from "react-router-dom";
import Header from "./global/Header";
import MainPage from "./pages/MainPage";
import AdvertisementPage from "./pages/AdvertisementPage";
import ServiceProviderPage from "./pages/ServiceProviderPage";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/advertisement/:advertisementId" element={<div>
          <Header />
          <AdvertisementPage />
        </div>
        } />
        <Route exact path="/user/:serviceProviderId" element={<div>
          <Header />
          <ServiceProviderPage />
        </div>
        } />

        <Route exact path="*" element={<div style={{
          minHeight: "100vh",
          backgroundImage: "url('assets/images/background.png')",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}>
          <Header />
          <MainPage />
        </div>} />
      </Routes>

    </>
  );
}

export default App;
