import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import formatDate from '../../utils/dateFormatter';

function AdvertisementPage() {

    const { advertisementId } = useParams();

    const [advertisement, setAdvertisement] = useState(null);
    const [notFound, setNotFound] = useState(false);

    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    useEffect(() => {
        const fetchAdvertisementDeatils = async () => {
            axios.get(`${process.env.REACT_APP_API_URL}advertisement/getAdvertisementWithDetails?advertisementId=${advertisementId}&onlyActives=1`)
                .catch((err) => {
                    console.log("err: " + err);
                    setAdvertisement(null);
                    setNotFound(true);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setAdvertisement(response.data.advertisement);
                        document.title = response.data.advertisement.title;
                    } else {
                        setNotFound(true);
                    }
                });
        }

        fetchAdvertisementDeatils();


    }, [advertisementId]);

    return (
        <div>
            {advertisement &&
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "45px", paddingBottom: "45px" }}>
                    <div style={{ position: "relative" }}>
                        {advertisement.advertisement_contents[0].is_photo === 1 ?
                            <img className={"rounded12"} src={advertisement.advertisement_contents[0].content_link} alt="advertisement-lg" style={{ width: isSM ? 350 : isXS ? 260 : 800 }} />
                            :
                            <video className={"rounded12"} alt="advertisement-lg" controls style={{ width: isSM ? 350 : isXS ? 260 : 800 }}>
                                <source src={advertisement.advertisement_contents[0].content_link + "#t=0.2"} />
                                Your Browser does not support HTML video.
                            </video>
                        }
                        {!!advertisement.is_truly_featured &&
                            <div
                                style={{
                                    background: "#8C52FF",
                                    position: "absolute",
                                    left: "10px",
                                    top: "10px",
                                    padding: "4px 12px"
                                }}
                                className={'rounded12'}
                            >
                                <p style={{ fontSize: "16px", margin: 0, color: "white" }}>Urgent Ad</p>
                            </div>
                        }
                    </div>
                    <div style={{ display: "flex", gap: "10px", ...(isSM || isXS ? { overflowX: "scroll" } : { flexWrap: "wrap" }), width: isSM ? 350 : isXS ? 260 : 800, marginTop: "8px" }}>
                        {advertisement.advertisement_contents.map((content, index) => {
                            if (index === 0) {
                                return null;
                            } else {
                                if (content.is_photo === 1) {
                                    return <img key={content.id} className={"rounded12"} src={content.content_link} alt={`advertisement-sm-${index}`} width={isXS ? 200 : 260} />
                                } else {
                                    return <video key={content.id} className={"rounded12"} src={content.content_link + "#t=0.2"} alt={`advertisement-sm-${index}`} width={isXS ? 200 : 260} controls>
                                        <source src={content.content_link + "#t=0.2"} />
                                        Your Browser does not support HTML video.
                                    </video>
                                }
                            }
                        })}
                    </div>
                    <div style={{ width: isSM ? 350 : isXS ? 260 : 800 }}>
                        <h1 style={{ fontSize: "24px", fontWeight: 900 }}>{advertisement.title}</h1>
                        <p style={{ fontSize: "16px", whiteSpace: "pre-line" }}>{advertisement.description}</p>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px"
                            }}
                        >
                            <div style={{ display: "flex", gap: "4px", alignItems: "center" }} >
                                <img className={"rounded12"} src={advertisement.user_profile_photo_link} alt="user-profile" width={24} height={24} />
                                <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.user_name} {advertisement.user_surname}</p>
                            </div>
                            <div className={'rounded12'} style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.city}</p>
                            </div>
                            <div className={'rounded12'} style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.county}</p>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <p style={{ fontSize: "14px", color: "#999999", margin: 0 }}>Ad Number: {advertisement.id}</p>
                            <p style={{ fontSize: "14px", color: "#999999", margin: 0 }}>{formatDate(advertisement.created_date)}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "37px" }}>
                            <button style={{
                                background: "#8C52FF",
                                padding: "16px 0",
                                width: isSM ? "50%" : isXS ? "60%" : "35%",
                                textAlign: "center",
                                border: "none"
                            }} className={'rounded16 app-button'} >
                                <p style={{ fontSize: "18px", margin: 0, color: "white", userSelect: "none" }}>See in App</p>
                            </button>
                        </div>
                    </div>
                </div>
            }
            {
                notFound &&
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "65vh" }}>
                    <h1 style={{ fontSize: "100px", fontWeight: 900, margin: 0, color: "#8C52FF" }}>404</h1>
                    <h2 style={{ fontSize: "24px", fontWeight: 900, margin: 0 }}>Ad Not Found</h2>
                </div>
            }
        </div>
    )
}

export default AdvertisementPage